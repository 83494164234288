.pickDirection-container {
    margin: $s-size auto $m-size;
    justify-content: space-around;
    text-align: center;
    .pickDirection-buttons {
        justify-content: space-around;
        text-align: center;
        display: flex;
        margin: $s-size auto;
    }

    .directionValue {
        display: flex;
        justify-content: center;
        font-size: $calcFontSizeLarge;
    }

    .buttonShadow {
        @include box-shadow-blue;
    }

    button {
        @include border-square;
        @include border-blue;
        background-color: none;
        background: none;

        color: $mid-blue;
        padding: $s-size;
        text-decoration: none;
        font-size: $calcFontSizeLarge;
        margin: auto;
        cursor: pointer;
    }
}

@media only screen and (min-device-width: $desktop-breakpoint) {
    .pickDirection-container {
        margin: $s-size auto $m-size;
        justify-content: space-around;
        text-align: center;
        .pickDirection-buttons {
            justify-content: space-around;
            text-align: center;
            display: flex;
            margin: $s-size auto;
        }

        .directionValue {
            display: flex;
            justify-content: center;
            font-size: $calcFontSize;
        }

        .buttonShadow {
            @include box-shadow-blue;
        }

        button {
            @include border-square;
            @include border-blue;
            background-color: none;
            background: none;

            color: $mid-blue;
            padding: $s-size;
            text-decoration: none;
            font-size: $calcFontSize;
            margin: auto;
            cursor: pointer;
        }
    }
}
