.header-container {
    display: flex;

    .leftBlock {
        display: flexbox;
        width: 20%;
        justify-content: center;
        .leftTop {
            height: 20%;
        }

        .backLink {
            justify-content: center;
            text-align: center;
            height: 50%;
            width: 100%;
            padding: $xs-size;
            font-size: $calcFontSizeExtraLarge;
        }

        .backLink:hover {
            @include border;
            @include border-orange;
            @include box-shadow-orange;
        }

        .leftBottom {
            height: 25%;
        }
    }

    .centerBlock {
        width: 60%;
        text-align: center;
        justify-content: center;
        font-size: $calcFontSizeLarge;
        h3 {
            text-align: center;
            justify-content: center;
        }
    }

    .rightBlock {
        width: 20%;
    }
}

@media only screen and (min-device-width: $desktop-breakpoint) {
    .header-container {
        .leftBlock {
            .leftTop {
                height: 10%;
            }

            .backLink {
                justify-content: center;
                text-align: center;
                height: 80%;
                width: 100%;
                font-size: $calcFontSizeLarge;

                a {
                    display: inline-block;
                    width: fill;
                    height: fill;
                }
            }

            .leftBottom {
                height: 10%;
            }
        }

        .centerBlock {
            font-size: $calcFontSize;
        }
    }
}
