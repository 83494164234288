.hyperdriveDisplay-container {


    .button-container {
        display: flex;
        flex-wrap: row wrap;

        a {
            @include border;
            @include border-blue;
            text-align: center;
            padding: $s-size 0;
            margin: $xs-size 0;
            text-decoration: none;
            width: 100%;
            font-size: $calcFontSizeLarge;
        }

        a:hover {
            color: $orange-tan;
            @include box-shadow-orange;
        }
    }

    .roll-container {
        display: flex;
        justify-content: space-around;
    }

    button {
        @include border;
        @include border-blue;
        color: $mid-blue;
        background: none;
        text-align: center;
        font-size: $calcFontSizeLarge;
        width: 100%;
        height: 100%;
    }

    button:hover {
        color: $orange-tan;
        @include border-orange;
        @include box-shadow-orange;
        cursor: pointer;
    }

    .rollButton {
        width: 50%;
    }

    .rollResult-container {
        justify-content: center;
        width: 25%;
        @include outline;
        @include outline-blue;
        text-align: center;
    }

    .rollResult {
        justify-content: center;
        font-size: $calcFontSizeLarge;
    }

    .bodyText {
        margin-top: 0;
    }

    .center {
        text-align: center;
    }
}

@media only screen and (min-device-width: $desktop-breakpoint) {
    .hyperdriveDisplay-container {
        .header-container {
            display: flex;
            flex-wrap: row wrap;
        }

        .button-container {
            display: flex;
            justify-content: center;

            a {
                @include border;
                text-align: center;
                padding: $s-size 0;
                margin: $xs-size 0;
                font-size: $calcFontSize;
                text-decoration: none;
                width: 100%;
            }

            a:hover {
                color: $orange-tan;
                @include box-shadow-orange;
            }
        }

        .roll-container {
            display: flex;
            justify-content: space-around;
        }

        .rollButton {
            width: 50%;
        }

        .rollResult-container {
            justify-content: center;
            width: 25%;
            @include outline;
            @include outline-blue;
            text-align: center;
        }

        .rollResult {
            justify-content: center;
            font-size: larger;
        }


        button {
            @include border;
            @include border-blue;
            background-color: none;
            height: 100%;
            font-size: $calcFontSize;
        }
    }
}
