.newContinue-container {
    display: flex;
    justify-content: space-around;
    margin: $m-size 0;
    padding: $m-size 0;
    font-size: $calcFontSizeLarge;

    .newContinue-link {
        text-align: center;
        min-width: 25%;
    }

    a {
        margin: $s-size;
        padding: $m-size;
        text-align: center;
        @include border;
    }
}

@media only screen and (min-device-width: $desktop-breakpoint) {
    .newContinue-container {
        font-size: $calcFontSize;
        .newContinue-link {
            min-width: 50%;
        }

        a {
            padding: $s-size;
            text-decoration: none;
        }
    }
}
