.page-container {
    margin: 0 auto;
    max-width: 60rem;
    padding: 0;
    padding-bottom: $s-size;
    min-height: 99vh;
    // height:99vh;
    font-size: $calcFontSize;
    background-image: url("../../images/starBackground-Cut.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;
    background-position: center;
}

@media only screen and (min-device-width: $desktop-breakpoint) {
    .page-container {
        background-image: url("../../images/starBackground\(Shorter2\).jpg");
        background-size: 60rem 98%;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Arial", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $dark-grey;
    color: $mid-blue;
}

hr {
    margin: 0;
    background-color: $mid-blue;
}
