.nav-center-container {
    font-size: $calcFontSizeLarge;
    justify-content: center;
    text-align: center;
    display: flex;
}

.nav-link {
    @include nav-link;
    @include nav-link-settings-small;
}

.nav-link:hover {
    @include nav-link-hover;
    @include nav-link-settings-small;
    @include outline;
}

.nav-link-selected {
    @include nav-link-selected;
    @include nav-link-settings-small;
    text-decoration: underline;
}

.nav-link-selected:hover {
    @include nav-link-selected-hover;
    @include nav-link-settings-small;
}

@media only screen and (min-device-width: $desktop-breakpoint) {
    .nav-center-container {
        font-size: $calcFontSize;
        flex-flow: row wrap;
        align-content: center;
        align-items: space-evenly;
    }

    .nav-link {
        @include nav-link;
        @include nav-link-settings-large;
    }

    .nav-link-selected {
        text-decoration: none;
        @include nav-link-selected;
        @include nav-link-settings-large;
        @include outline;
        @include outline-blue;
    } 

    .nav-link:hover {
        @include nav-link-hover;
        @include nav-link-settings-large;
        @include outline;   
        @include outline-orange;
    }

    .nav-link-selected:hover {
        @include nav-link-selected-hover;
        @include nav-link-settings-large;
    }
}
