.nextBar-container {
    display: flex;
    justify-content: center;
    text-align: center;
    width: fill;
    font-size: $calcFontSizeLarge;

    a {
        @include border;
        color: $mid-blue;
        padding: $xs-size;
        margin: $xs-size;
        width: fill;
        text-decoration: none;
    }

    a:hover {
        color: $orange-tan;
        @include box-shadow-orange;
    }
}

.nextBar-container-blue {
    display: flex;
    justify-content: center;
    text-align: center;
    width: fill;
    font-size: $calcFontSizeLarge;

    a {
        @include border;
        color: $mid-blue;
        padding: $xs-size;
        margin: $xs-size;
        width: fill;
        text-decoration: none;
        @include box-shadow-blue;
    }

    a:hover {
        color: $orange-tan;
        @include box-shadow-orange;
    }
}

@media only screen and (min-device-width: $desktop-breakpoint) {
    .nextBar-container {
        font-size: $calcFontSize;

        a {
            @include border;
            padding: $s-size;
            margin: $s-size;
        }

        a:hover {
            color: $orange-tan;
            @include box-shadow-orange;
        }
    }

    .nextBar-container-blue {
        font-size: $calcFontSize;

        a {
            @include border;
            padding: $s-size;
            margin: $s-size;
        }
    }
}
