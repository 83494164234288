.markSelector-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: $xs-size 0;

    a {
        @include border;
        text-align: center;
        padding: $s-size 0;
        margin: $xs-size 0;

        text-decoration: none;
        width: 100%;
    }

    a:hover {
        color: $orange-tan;
        @include box-shadow-orange;
    }
}

@media only screen and (min-device-width: $desktop-breakpoint) {
    .markSelector-container {
        justify-content: space-evenly;

        a {
            width: 47%;
        }
    }
}
