.header {
    display: flexbox;
    justify-content: center;
    text-align: center;

    h1 {
        margin: 0 $xs-size;
        padding: $xs-size;
    }

    h3 {
        font-style: italic;
        margin: 0 $xs-size;
        padding: 0;
    }

    .divSpacer{
        margin-bottom: $s-size;
    }
}

@media only screen and (min-device-width: $desktop-breakpoint) {
    .header {
        display: flex;
        justify-content: space-between;


        h1 {
            margin: 0 $m-size;
            padding: $s-size;
            text-align: left;
        }

        h3 {
            margin: 0 $s-size;
            text-align: right;
        }

        .divSpacer {
            margin: $s-size;
            margin-right: $m-size;
        }
    }
}
