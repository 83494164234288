.introduction-container {
    text-align: center;
    display: flex;
    justify-content: center;

    .attribution {
        position: absolute;
        bottom: -0vh;
        margin-bottom: 10px;
    }
}
